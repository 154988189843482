import './BreadCrumbs.scss';
import { Link } from 'react-router-dom';
export default function BreadCrumbs({ crumbs }) {
  return <div className="breadcrumbs">{crumbs.map(renderCrumb)}</div>;
}

function renderCrumb({ label, to }, id) {
  return (
    <div className="crumb" key={id}>
      {to ? <Link to={to}>{label}</Link> : label}
    </div>
  );
}

BreadCrumbs.builder = function (crumbs) {
  function add(crumb) {
    return BreadCrumbs.builder([...crumbs, crumb]);
  }

  function render() {
    return <BreadCrumbs crumbs={crumbs} />;
  }
  return { add, render };
};

import ProductSummary from './ProductSummary';
import illustration from '../../asset/payment-terminal-sdk-diagram.webp';

export default function PaymentSdkCard() {
  return (
    <ProductSummary
      id="payment-terminal-sdk"
      photo={illustration}
      short="Payment Terminal JavaScript SDK"
      desc="Vanilla JavaScript SDK for kiosk/web app payment terminal integration."
    />
  );
}

import notification from '../../api/notification';
import { redirect } from 'react-router-dom';
import { Form, Formik } from 'formik';
import SubmitBtn from '../../component/form/SubmitBtn';
import * as y from 'yup';
import TextField from '../../component/form/TextField';
import TextareaField from '../../component/form/TextareaField';
import { useTranslation } from 'react-i18next';

const contactSchema = y.object({
  contact: y.string().max(100).required(),
  name: y.string().max(50).required(),
  msg: y.string().max(3000),
});
export default function RequestDemoForm({ productId }) {
  const { t } = useTranslation();
  function handleSubmit(dto) {
    return notification.contactUs({ productId, ...dto }).then(
      function success() {
        alert(
          'Thank you. We have received your details. We will reach out to you as soon as possible.'
        );
      },
      function fail() {
        alert(
          'Sorry, there was an error and we did not receive your details. Please drop us an email at info@meovak.com or call us at +65 8768 6989. Thank you for your understanding.'
        );
        redirect('/contact');
      }
    );
  }

  return (
    <Formik
      validationSchema={contactSchema}
      initialValues={{ name: '', contact: '', msg: '' }}
      onSubmit={handleSubmit}
    >
      <Form className="pure-form pure-form-stacked" method="dialog">
        <fieldset className="flow-content">
          <TextField
            id="name"
            label={t('contact:labelFullName')}
            placeholder={t('contact:placeholderFullName')}
          />
          <TextField
            id="contact"
            label={t('contact:labelEmail')}
            placeholder={t('contact:placeholderEmail')}
          />
          <TextareaField
            id="msg"
            label={t('contact:labelMessage')}
            placeholder={t('contact:placeholderMessage')}
          />
        </fieldset>

        <SubmitBtn className="btn-blk">{t('contact:sendRequest')}</SubmitBtn>
      </Form>
    </Formik>
  );
}
RequestDemoForm.productId = {
  docushare: 'docushare',
  paymentTerminalSdk: 'ptsdk',
  meovak: 'meovak',
};

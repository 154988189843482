import { forwardRef } from 'react';

const Modal = forwardRef(function (props, ref) {
  return (
    <dialog ref={ref} {...props} className="flow-content">
      <header>
        <div className="dialog-title">
          <span>{props.title}</span>
        </div>
        <div className="dialog-buttons">
          <button
            type="button"
            className="pure-button"
            onClick={() => ref.current.close()}
          >
            ✖️
          </button>
        </div>
      </header>
      <main className="container">{props.children}</main>
    </dialog>
  );
});
export default Modal;

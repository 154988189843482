import './docushare.scss';
import Page from '../layout/Page';
import diagram from './asset/flow-diagram.svg';
import Modal from '../../component/Modal';
import { useRef } from 'react';
import Button from '../../component/Button';
import RequestDemoForm from '../../component/cta/RequestDemoForm';
import docushareWeb from './asset/docushare-web.jpg';
import docushareSms from './asset/docushare-sms.jpg';
import docushareEmail from './asset/docushare-email.jpg';
import docushareDisk from './asset/docushare-disk.jpg';
import docushareLogo from './asset/docushare-logo.svg';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import productBreadcrumbs from '../../component/breadcrumbs/productBreadcrumbs';

export default function Docushare() {
  const contactDialog = useRef();
  const { t } = useTranslation();
  return (
    <Page>
      <Helmet>
        <title>Docushare</title>
        <meta
          name="description"
          content="Customer service centre document sharing made simple. Third party document sharing via SMS/email, network disk shared folder."
        />
      </Helmet>
      <main className="container flow-content">
        {productBreadcrumbs.add({ label: 'Docushare' }).render()}
        <section className="flow-content">
          <img src={docushareLogo} alt="Docushare logo" id="docushareLogo" />
          <p className="txt-xl">{t('docushare:title')}</p>
          <p>{t('docushare:des')}</p>
          <div>
            <Button
              onClick={() => contactDialog.current.showModal()}
              className="pure-button-primary btn-xl"
            >
              {t('docushare:tryItNow')}
            </Button>
          </div>
        </section>
        <section className="docushare-features flow-content">
          <h2>{t('docushare:features')}</h2>
          <div className="split">
            <p>{t('docushare:feature1')}</p>
            <img src={docushareWeb} alt="Docushare web view" />
          </div>
          <div className="split mirrored">
            <p>{t('docushare:feature2')}</p>
            <img src={docushareDisk} alt="Docushare mounted network disk" />
          </div>
          <div className="split">
            <p>{t('docushare:feature3')}</p>
            <img src={docushareEmail} alt="Docushare email view" />
            <img src={docushareSms} alt="Docushare sms mobile view" />
          </div>
          <div>
            <Button
              onClick={() => contactDialog.current.showModal()}
              className="pure-button-primary btn-xl"
            >
              {t('docushare:requestDemo')}
            </Button>
          </div>
        </section>
        <section className="flow-content">
          <h3>{t('docushare:techSpecs')}</h3>
          <div className="center">
            <p>{t('docushare:diagram')}</p>
            <div>
              <img src={diagram} alt="document upload flow diagram" />
            </div>
          </div>
        </section>
      </main>

      <Modal ref={contactDialog} title="Demo request">
        <RequestDemoForm productId={RequestDemoForm.productId.docushare} />
      </Modal>
    </Page>
  );
}

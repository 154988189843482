import React from 'react';
import ReactDOM from 'react-dom/client';
import 'purecss/build/pure-min.css';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import './index.scss';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { HelmetProvider } from 'react-helmet-async';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router}></RouterProvider>
    </HelmetProvider>
  </React.StrictMode>
);

import './TwoCols.scss';
export default function TwoCols({ title, children }) {
  return (
    <section className="two-cols split container my-3">
      <div className="col txt-xxl">
        <b>{title}</b>
      </div>
      <div className="col">{children}</div>
    </section>
  );
}

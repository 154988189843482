import Menu from './Menu';
import Footer from './Footer';
import './page.scss';
import classNames from 'classnames';

export default function Page({ children, className }) {
  return (
    <div className={classNames('page', className)}>
      <Menu />
      {children}
      <Footer />
    </div>
  );
}

import './footer.scss';
import linkedin from './asset/linkedin.svg';
import LogoGroup from './LogoGroup';
import MyNavLink from './MyNavLink';
export default function Footer() {
  const today = new Date();
  return (
    <footer className="page-footer flex-center flow-content">
      <div>
        <LogoGroup />
        <nav className="pure-menu pure-menu-horizontal">
          <ul className="pure-menu-list flow-content-horizontal">
            <MyNavLink to="/">About us</MyNavLink>
            <MyNavLink to="/contact">Contact</MyNavLink>
            {/*<MyNavLink to="/blog">Blog</MyNavLink>*/}
          </ul>
        </nav>
        <p className="secondary">
          Copyright {today.getFullYear()}, Meovak Pte Ltd. All rights reserved.
        </p>
      </div>
      <div>
        <p>
          <a
            href="https://www.linkedin.com/company/meovak"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="Meovak Linkedin" />
          </a>
        </p>
        <p className="secondary">
          Web designed by{' '}
          <a
            href="https://www.linkedin.com/in/anynguyen96"
            target="_blank"
            rel="noreferrer"
          >
            Any Nguyen
          </a>
        </p>
      </div>
    </footer>
  );
}

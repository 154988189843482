import { NavLink } from 'react-router-dom';

export default function MyNavLink({ children, ...props }) {
  return (
    <li className="pure-menu-item" role="presentation">
      <NavLink className="pure-menu-link" {...props} role="menuitem">
        {children}
      </NavLink>
    </li>
  );
}

import LiteYoutube from 'react-lite-youtube-embed';
import Page from '../layout/Page';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Button from '../../component/Button';
import RequestDemoForm from '../../component/cta/RequestDemoForm';
import Modal from '../../component/Modal';
import { useRef } from 'react';
import diagram from '../asset/payment-terminal-sdk-diagram.webp';
import SampleCode from './SampleCode';
import productBreadcrumbs from '../../component/breadcrumbs/productBreadcrumbs';

export default function PaymentTerminalSdk() {
  const { t } = useTranslation();
  const contactDialog = useRef();

  const cta = (
    <div>
      <Button
        className="pure-button-primary btn-xl"
        onClick={() => contactDialog.current.showModal()}
      >
        {t('ptsdk:cta')}
      </Button>
    </div>
  );
  return (
    <Page>
      <Helmet>
        <title>Payment Terminal Web SDK</title>
        <meta
          name="description"
          content="Vanilla JavaScript SDK for kiosk web app payment terminal integration. Visa Mastercard Ingenico card payment."
        />
      </Helmet>
      <main className="container">
        {productBreadcrumbs.add({ label: 'Terminal SDK' }).render()}
        <section
          className="flow-content"
          itemScope
          itemType="https://schema.org/SoftwareApplication"
        >
          <h1 itemProp="name">{t('ptsdk:title')}</h1>
          <p className="txt-xl" itemProp="description">
            {t('ptsdk:desc')}
          </p>
          {cta}
          <div itemProp="video">
            <LiteYoutube
              id="mOSzSa_SVOU"
              title="Visa Mastercard payment using JavaScript API"
            />
          </div>
          <div style={{ display: 'none' }}>
            <span itemProp="applicationCategory">sdk</span>
          </div>
        </section>
        <section className="flow-content">
          <h2>Use cases</h2>
          <ul>
            <li>Self-help kiosks.</li>
            <li>Point of sale systems (POS).</li>
            <li>Cashier app.</li>
            <li>
              Adding card payment capabilities to your existing web application.
            </li>
            <li>
              Payment on any operating systems (Ex:{' '}
              <span itemProp="operatingSystem">
                Windows, OS X, Android, Chrome OS, Tizen OS, Linux, Ubuntu
              </span>
              ...).
            </li>
            <li>
              This is useful for non-Windows machines like Samsung's kiosks or
              smart TVs with touchscreen running on Tizen OS where you can only
              control terminals using JavaScript programming language.
            </li>
          </ul>
        </section>
        <section className="flow-content">
          <h2>Payment terminal integration</h2>
          <p>
            Our JavaScript SDK allows your web application to connect and
            control a payment terminal directly from a web browser.
          </p>
          <p>
            Regardless of operating system, you may run your web app in any of
            these supported browsers:
          </p>
          <ul>
            <li>Google Chrome</li>
            <li>Microsoft Edge</li>
            <li>Opera</li>
          </ul>
          <div className="center">
            <img src={diagram} alt="payment-terminal-integration-diagram" />
          </div>
          {cta}
          <h3>Sample code</h3>
          <p>Our terminal SDK is a promise API.</p>
          <div>
            <SampleCode />
          </div>
        </section>
      </main>
      <Modal ref={contactDialog} title="Demo request">
        <RequestDemoForm
          productId={RequestDemoForm.productId.paymentTerminalSdk}
        />
      </Modal>
    </Page>
  );
}

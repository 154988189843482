import { NavLink } from 'react-router-dom';
import './menu.scss';
import { useEffect, useRef } from 'react';
import PureDropdown from '../../lib/PureDropdown';
import LogoGroup from './LogoGroup';
import MyNavLink from './MyNavLink';

export default function Menu() {
  return (
    <nav className="pure-menu pure-menu-horizontal main-menu flex-center">
      <NavLink to="/" className="pure-menu-link">
        <LogoGroup />
      </NavLink>
      <ul className="pure-menu-list">
        <SubMenu id="menuLink1" label="Products">
          <MyNavLink to="/docushare">Docushare</MyNavLink>
          <MyNavLink to="/payment-terminal-sdk">
            Payment Terminal Web SDK
          </MyNavLink>
        </SubMenu>
        <MyNavLink to="/contact">Contact</MyNavLink>
        <li className="pure-menu-item" role="presentation">
          <button className="pure-menu-link pure-button">
            🇬🇧 <span className="collapsible">EN</span>
          </button>
        </li>
      </ul>
    </nav>
  );
}

function SubMenu({ id, label, children }) {
  const ref = useRef(null);
  useEffect(() => {
    new PureDropdown(ref.current);
  }, []);
  return (
    <li
      className="pure-menu-item pure-menu-has-children pure-menu-allow-hover"
      ref={ref}
    >
      <a href="#top" id={id} className="pure-menu-link">
        {label}
      </a>
      <ul
        className="pure-menu-children shadow"
        role="menu"
        aria-labelledby={id}
        aria-hidden="true"
      >
        {children}
      </ul>
    </li>
  );
}

import MyField from './MyField';
import { Field } from 'formik';

export default function TextareaField(props) {
  const { id, rows } = props;
  return (
    <MyField {...props}>
      <Field
        name={id}
        id={id}
        component="textarea"
        rows={rows || 5}
        {...props}
      />
    </MyField>
  );
}

import ErrorMsg from './ErrorMsg';

export default function MyField({ label, id, children }) {
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      {children}
      <ErrorMsg name={id} />
    </>
  );
}

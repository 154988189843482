import './Home.scss';
import Page from '../layout/Page';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import TwoCols from '../../component/TwoCols';
import passImg from './meovak-pass.webp';
import Carousel from '../../component/carousel/Carousel';
import { DocushareCard, PaymentSdkCard } from './products';

function Home() {
  const { t } = useTranslation();
  return (
    <Page>
      <Helmet>
        <title>Meovak</title>
        <meta
          name="description"
          content="Meovak Pte Ltd. Information technology solutions."
        />
      </Helmet>
      <main className="flow-content flow-content--3x mb-3">
        <TwoCols title={t('home:title')}>
          <p>
            Meovak{' '}
            <span className="secondary">
              is a rising tech start-up company fueled by the passion of its
              founder, a seasoned developer with an unwavering dedication to
              bridging the gap between cutting-edge technology and real-world
              customer needs
            </span>
            .
          </p>
        </TwoCols>
        <div>
          <img src={passImg} alt="Meovak pass" />
        </div>
        <section className="container center">
          <h2>Driven by desire</h2>
          <p className="secondary">
            to leverage the power of innovation to positively impact lives, our
            team crafts intuitive and accessible solutions that empower
            individuals and businesses alike.
          </p>
        </section>
        <section>
          <Carousel
            Components={[DocushareCard, PaymentSdkCard]}
            className="carousel--products"
          />
        </section>
      </main>
    </Page>
  );
}

export default Home;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as locales from './resources';

const defaultLang = 'en';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: defaultLang,
    fallbackLng: defaultLang,
    resources: {
      ...Object.entries(locales).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
        }),
        {}
      ),
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

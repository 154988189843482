export default function SampleCode() {
  return (
    <pre rel="JS">
      <code>
        <div>
          <span className="keyword">import</span> terminal
          <span className="keyword"> from</span>
          <span className="data"> '@meovak/terminal'</span>;
        </div>
        <br />
        <div className="comment">
          &#47;&#47; Trigger a credit card payment (Visa, Mastercard...)
        </div>
        <div>
          document.<span className="func">getElementById</span>(
          <span className="data">'pay-by-credit-card'</span>).onclick =
          <span className="keyword"> async</span>
          <span className="keyword"> function</span> () &#123;
        </div>
        <div>
          <span className="keyword">&#09;const</span> terminalResponse =
          <span className="keyword"> await</span> terminal.
          <span className="func">makeCreditCardPayment</span>(
          <span className="data">567</span>);
          <span className="comment"> &#47;&#47; 5 dollar 67 cent</span>
        </div>
        <div>&#125;</div>
        <br />
        <div className="comment">
          &#47;&#47; Trigger a debit card payment (for Singapore market, this is
          NETS payment)
        </div>
        <div>
          document.<span className="func">getElementById</span>(
          <span className="data">'pay-by-nets-debit-card'</span>
          ).onclick = <span className="keyword">async</span>
          <span className="keyword"> function</span> () &#123;
        </div>
        <div>
          <span className="keyword">&#09;const</span> terminalResponse =
          <span className="keyword"> await</span> terminal.
          <span className="func">makeDebitCardPayment</span>(
          <span className="data">123</span>);
          <span className="comment"> &#47;&#47; 1 dollar 23 cent</span>
        </div>
        <div>&#125;</div>
        <br />
        <div className="comment">
          &#47;&#47; and the rest of the functions that are available for
          particular terminal model
        </div>
        <div>
          terminal.<span className="func">logon</span>()
        </div>
        <div>
          terminal.<span className="func">reset</span>()
        </div>
        <div>
          terminal.<span className="func">status</span>()
        </div>
        <div>...</div>
      </code>
    </pre>
  );
}

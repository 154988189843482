import { useTranslation } from 'react-i18next';
import Page from '../layout/Page';
import ContactBanner from './asset/contact-banner.webp';
import RequestDemoForm from '../../component/cta/RequestDemoForm';
import Card from '../../component/Card';
import TwoCols from '../../component/TwoCols';
import homeBreadcrumbs from '../../component/breadcrumbs/homeBreadcrumbs';

export default function Contact() {
  const email = {
    info: 'info@meovak.com',
    tech: 'support@meovak.com',
  };
  const { t } = useTranslation();
  return (
    <Page className="contact-page flow-content">
      {homeBreadcrumbs.add({ label: 'Contact' }).render()}
      <TwoCols title={t('contact:weAreHere')}>
        <ul className="contacts">
          <li>
            <span className="fw-500">{t('contact:email')}: </span>
            <a href={`mailto:${email.info}`}>{email.info}</a>
          </li>
          <li>
            <span className="fw-500">{t('contact:phone')}: </span>
            <a href="tel:+6587686989">(+65) 8768 6989</a>
          </li>
          <li>
            <span className="fw-500">{t('contact:technicalSupport')}: </span>
            <a href={`mailto:${email.tech}`}>{email.tech}</a>
          </li>
        </ul>
      </TwoCols>
      <div>
        <img src={ContactBanner} alt="Contact meovak" />
      </div>
      <TwoCols title={t('contact:ourTeamWill')}>
        <Card>
          <RequestDemoForm productId={RequestDemoForm.productId.meovak} />
        </Card>
      </TwoCols>
    </Page>
  );
}

const TRANSLATIONS_MY = {
  global: {},
  home: {
    title: 'We hack and build ridiculously good apps for you.',
    sectors: 'Sectors',
    customer: 'Customer service management',
    nets: 'NETS payment integration',
  },
  docushare: {
    title:
      'Docushare helps customer service officers receive documents from customers faster with configurable file size up to 100MB.',
    des: `Still receiving files via sluggish emails? Docushare eliminates awkward waiting moments 'for files to arrive' between you and your customers. With Docushare, sharing and receiving files are instant, handy and secured.`,
    tryItNow: 'Try it now',
    features: 'Features',
    feature1: `Docushare allows your customers to upload supporting documents via QR code using queue number as a reference. Documents are immediately sent to your customer service officer's computer.`,
    feature2: `Documents from your customers are sent to a shared network drive in your customer officer's computer. For your quick navigation, documents are organized by date time, officer name, and queue number.`,
    feature3: `If your customers require documents from a third party (family/colleague), Docushare is able to send them an invitation link via email or SMS.`,
    requestDemo: 'Request Demo',
    techSpecs: 'Tech Specs',
    diagram: 'Document upload flow diagram',
  },
  contact: {
    email: 'Email',
    phone: 'Phone',
    technicalSupport: 'Technical support',
    weAreHere: 'We are here for you',
    ourTeamWill:
      'Our team will reach out to you. Let us know your contact details.',
    sendRequest: 'Send Request',
    labelFullName: 'Your name',
    placeholderFullName: 'Please give us your full name',
    labelEmail: 'Your phone or email',
    placeholderEmail: 'How can we reach out to you?',
    labelMessage: 'Your message',
    placeholderMessage:
      'Could you please provide us with more details about your request?',
  },
};

export default TRANSLATIONS_MY;

import ProductSummary from './ProductSummary';
import illustration from './docushare-illustration.webp';

export default function DocushareCard() {
  return (
    <ProductSummary
      id="docushare"
      short="With Docushare, sharing and receiving files are instant, handy and secured."
      desc="Still receiving files via sluggish emails? Docushare eliminates awkward waiting moments 'for files to arrive' between you and your customers. With Docushare, sharing and receiving files are instant, handy and secured."
      photo={illustration}
    />
  );
}

import './ProductSummary.scss';
import Card from '../../../component/Card';
import { NavLink } from 'react-router-dom';

export default function ProductSummary({ photo, id, short, desc }) {
  return (
    <Card>
      <div className="product-summary flow-content">
        <div className="split">
          <div className="center">
            <img src={photo} alt={`product ${id} illustration`} />
          </div>
          <div className="flow-content">
            <strong className="h2">{short}</strong>
            <p>{desc}</p>
          </div>
        </div>
        <div className="cta center">
          <NavLink to={`/${id}`} className="pure-button pure-button-primary">
            Learn more
          </NavLink>
        </div>
      </div>
    </Card>
  );
}

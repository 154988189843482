import React from 'react';
import { useFormikContext } from 'formik';
import Spinner from '../Spinner';
import classNames from 'classnames';

export default function SubmitBtn({ className, children }) {
  const { isValid, isSubmitting, dirty } = useFormikContext();
  return (
    <div>
      <button
        className={classNames('pure-button pure-button-primary', className)}
        type="submit"
        disabled={!dirty || !isValid || isSubmitting || null}
      >
        {children} {isSubmitting && <Spinner color="white" size={16} />}
      </button>
    </div>
  );
}

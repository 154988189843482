import { createBrowserRouter } from 'react-router-dom';
import Home from './page/home/Home';
import React from 'react';
import Contact from './page/contact/Contact';
import Docushare from './page/docushare/Docushare';
import Blog from './page/Blog';
import PaymentTerminalSdk from './page/paymentTerminal/PaymentTerminalSdk';

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/contact', element: <Contact /> },
  { path: '/blog', element: <Blog /> },
  { path: '/docushare', element: <Docushare /> },
  { path: '/payment-terminal-sdk', element: <PaymentTerminalSdk /> },
]);
export default router;

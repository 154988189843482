import Page from './layout/Page';

export default function Blog() {
  /**
   * Todo: redirect link to a CMS
   * CNAME: blog.meovak.com
   */
  return (
    <Page>
      <h2>Coming soon!</h2>
    </Page>
  );
}

import './Carousel.scss';
import { useState } from 'react';
import classNames from 'classnames';

export default function Carousel({ Components, className }) {
  const [index, setIndex] = useState(0);
  const [animation, setAnimation] = useState('fade-in');

  function animate(next) {
    return () => {
      setAnimation('fade-out');
      setTimeout(function () {
        next();
        setAnimation('fade-in');
      }, 200);
    };
  }

  const next = animate(() =>
    setIndex((current) => (current === Components.length - 1 ? 0 : current + 1))
  );
  const prev = animate(() =>
    setIndex((current) => (current === 0 ? Components.length - 1 : current - 1))
  );

  const Component = Components[index];
  return (
    <div className={classNames('carousel', className)}>
      <div className="carousel__control">
        <button className="pure-button btn--circle" onClick={prev}>
          {'<'}
        </button>
      </div>
      <div className={classNames('carousel__content', animation)}>
        <Component />
      </div>
      <div className="carousel__control">
        <button className="pure-button btn--circle" onClick={next}>
          >
        </button>
      </div>
    </div>
  );
}

import { Field } from 'formik';
import MyField from './MyField';

export default function TextField(props) {
  const { id } = props;
  return (
    <MyField {...props}>
      <Field type="text" name={id} id={id} {...props} />
    </MyField>
  );
}
